<template>
  <div class="dispatch">
    <InformationBanner></InformationBanner>
    <CookieBanner />
    <div class="_container">
      <SearchVehicle
        :title="$t('dispatch.search-vehicle')"
        :parent="true"
        page="dispatch"
        name="searchVehicle"
        :width="500"
        :height="500"
        @searchCivilian="searchCivilian($event)"
      />
      <SearchPerson
        :title="$t('dispatch.search-person')"
        :parent="true"
        page="dispatch"
        name="searchPerson"
        ref="searchPerson"
        :width="800"
        :height="500"
        @openArrest="openArrestationReport($event)"
        @openCitation="openViolationReport($event)"
      />
      <BroadcastPopup
        :title="$t('dispatch.broadcast')"
        :parent="true"
        page="dispatch"
        name="broadcast"
        :width="500"
        :height="500"
      />
      <PanicButtonsDispatch
        :title="$t('dispatch.panic-buttons')"
        :parent="true"
        page="dispatch"
        name="panic"
        :width="500"
        :height="500"
      />
      <ArchivesDispatch
        :title="$t('dispatch.archives')"
        :parent="true"
        page="dispatch"
        name="archives"
        :width="800"
        :height="500"
        @openCallDetails="openCallDetails($event)"
      />
      <CallDetailsPopup
        class="call-details-popup default"
        :title="$t('dispatch.call-details')"
        :parent="true"
        page="dispatch"
        name="callDetails"
        :width="800"
        :height="500"
        :callID="openedCallId"
      />
      <UnitDetails
        class="call-details-popup default"
        :title="$t('dispatch.unit-details')"
        :parent="true"
        page="dispatch"
        name="unitDetails"
        :width="800"
        :height="500"
        :unitId="openedUnitId"
        @unitOffDuty="openedUnitId = ''"
      />
      <Violation
        :title="$t('police.violation-report-viewer')"
        :parent="true"
        page="dispatch"
        name="violation-report-viewer"
        :width="800"
        :height="500"
        :issuer="$store.state.selection.character.id"
        action="view"
        :citationId="openedCitationId"
        @close="openedCitationId = null"
      />
      <Arrestation
        :title="$t('police.arrestation-report-viewer')"
        :parent="true"
        page="dispatch"
        name="arrestation-report-viewer"
        :width="800"
        :height="500"
        :issuer="$store.state.selection.character.id"
        action="view"
        :arrestationId="openedArrestationId"
        @close="openedArrestationId = null"
      />
      <div class="top">
        <ul>
          <li>
            <button
              class="orange v-step-vehicle-search"
              :class="{
                active: popupData()('dispatch', 'searchVehicle') && popupData()('dispatch', 'searchVehicle').active
              }"
              @click="$store.dispatch('popup/toggle', { page: 'dispatch', popup: 'searchVehicle' })"
            >
              <div class="icon">
                <i class="lc lc-car-search"></i>
              </div>
              <div class="title">
                <p>{{ $t('dispatch.search-vehicle') }}</p>
              </div>
            </button>
          </li>
          <li>
            <button
              class="orange v-step-name-search"
              :class="{
                active: popupData()('dispatch', 'searchPerson') && popupData()('dispatch', 'searchPerson').active
              }"
              @click="$store.dispatch('popup/toggle', { page: 'dispatch', popup: 'searchPerson' })"
            >
              <div class="icon">
                <i class="fas fa-id-card"></i>
              </div>
              <div class="title">
                <p>{{ $t('dispatch.search-person') }}</p>
              </div>
            </button>
          </li>
          <li>
            <button
              class="orange v-step-broadcast"
              :class="{ active: popupData()('dispatch', 'broadcast') && popupData()('dispatch', 'broadcast').active }"
              @click="$store.dispatch('popup/toggle', { page: 'dispatch', popup: 'broadcast' })"
            >
              <div class="icon">
                <i class="fas fa-broadcast-tower"></i>
              </div>
              <div class="title">
                <p>{{ $t('dispatch.broadcast') }}</p>
              </div>
            </button>
          </li>
          <li>
            <button
              class="orange v-step-panic"
              :class="{ active: popupData()('dispatch', 'panic') && popupData()('dispatch', 'panic').active }"
              @click="$store.dispatch('popup/toggle', { page: 'dispatch', popup: 'panic' })"
            >
              <div class="icon">
                <i class="fas fa-exclamation-circle"></i>
              </div>
              <div class="title">
                <p>{{ $t('dispatch.panic-buttons') }}</p>
              </div>
            </button>
          </li>
          <li>
            <button
              class="orange"
              disabled
            >
              <div class="icon">
                <i
                  class="fas fa-sticky-note"
                  style="color: #333;"
                ></i>
              </div>
              <div class="title">
                <p style="color: #333;">{{ $t('dispatch.notepad') }}</p>
              </div>
            </button>
          </li>
          <li>
            <button
              class="orange v-step-archives"
              :class="{ active: popupData()('dispatch', 'archives') && popupData()('dispatch', 'archives').active }"
              @click="$store.dispatch('popup/toggle', { page: 'dispatch', popup: 'archives' })"
            >
              <div class="icon">
                <i class="fas fa-archive"></i>
              </div>
              <div class="title">
                <p>{{ $t('dispatch.archives') }}</p>
              </div>
            </button>
          </li>
          <li>
            <button
              class="orange-full v-step-logout"
              @click="$router.push({ name: 'characters' })"
            >
              <div class="icon">
                <i
                  class="fas fa-sign-out-alt"
                  style="transform: rotate(180deg)"
                ></i>
              </div>
              <div class="title">
                <p>{{ $t('dispatch.logout') }}</p>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div class="content">
        <div class="calls-interface">
          <div class="active-calls col-pc-6 v-step-active-calls">
            <h3 class="title">{{ $t('dispatch.active-calls') }}</h3>
            <div class="table col-pc-12">
              <div class="tr header noselect">
                <div
                  class="th id col-pc-1"
                  :class="{ active: callsSort === 'sequentialId' }"
                  @click="sortCallsBy('sequentialId')"
                >
                  {{ $t('dispatch.id') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="callsSortDir === 'asc' && callsSort === 'sequentialId'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="callsSortDir === 'desc' && callsSort === 'sequentialId'"
                  ></i>
                </div>
                <div
                  class="th category col-pc-4"
                  :class="{ active: callsSort === 'category' }"
                  @click="sortCallsBy('category')"
                >
                  {{ $t('dispatch.category') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="callsSortDir === 'asc' && callsSort === 'category'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="callsSortDir === 'desc' && callsSort === 'category'"
                  ></i>
                </div>
                <div
                  class="th priority col-pc-2"
                  :class="{ active: callsSort === 'priority' }"
                  @click="sortCallsBy('priority')"
                >
                  {{ $t('dispatch.priority') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="callsSortDir === 'asc' && callsSort === 'priority'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="callsSortDir === 'desc' && callsSort === 'priority'"
                  ></i>
                </div>
                <div
                  class="th time col-pc-2"
                  :class="{ active: callsSort === 'time' }"
                  @click="sortCallsBy('time')"
                >
                  {{ $t('dispatch.time') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="callsSortDir === 'asc' && callsSort === 'time'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="callsSortDir === 'desc' && callsSort === 'time'"
                  ></i>
                </div>
                <div
                  class="th units col-pc-3"
                  :class="{ active: callsSort === 'units' }"
                  @click="sortCallsBy('units')"
                >
                  {{ $t('dispatch.units') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="callsSortDir === 'asc' && callsSort === 'units'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="callsSortDir === 'desc' && callsSort === 'units'"
                  ></i>
                </div>
              </div>
              <div
                class="tr"
                v-for="call in sortedCalls"
                :key="call.id"
                @click="openCallDetails(call.id)"
              >
                <div class="td id col-pc-1">
                  <span :title="call.sequentialId">{{ call.sequentialId }}</span>
                </div>
                <div class="td category col-pc-4">
                  <span :title="call.category">{{ call.category }}</span>
                </div>
                <div class="td priority col-pc-2">
                  <span :title="call.priority">{{ call.priority }}</span>
                </div>
                <div
                  class="td time col-pc-2"
                  :title="moment(call.createdAt).format('YYYY-MM-DD HH:mm:ss')"
                >
                  {{ moment(call.createdAt).format('HH:mm') }}
                </div>
                <div class="td units col-pc-3">
                  <div
                    class="inner"
                    :title="call.units.map(unit => unit.number).join(', ')"
                  >
                    {{ call.units.map(unit => unit.number).join(', ') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="create-a-call col-pc-6 v-step-create-call">
            <h3 class="title">{{ $t('dispatch.create-a-call') }}</h3>
            <div class="columns">
              <div class="column">
                <div class="caller input row">
                  <CustomInput
                    class="custom-input primary box col-pc-12"
                    inputClasses="col-pc-12 box"
                    :placeholder="$t('dispatch.caller-name')"
                    type="text"
                    v-model="$v.newCall.callerName.$model"
                    :model="$v.newCall.callerName"
                  ></CustomInput>
                </div>
                <div class="phone-number input row">
                  <CustomInput
                    class="custom-input primary box col-pc-12"
                    inputClasses="col-pc-12 box"
                    :placeholder="$t('dispatch.caller-phone')"
                    type="text"
                    v-model="$v.newCall.callerPhone.$model"
                    :model="$v.newCall.callerPhone"
                  ></CustomInput>
                </div>
                <div class="location input row">
                  <CustomInput
                    class="custom-input primary box col-pc-12"
                    inputClasses="col-pc-12 box"
                    :placeholder="$t('dispatch.call-location')"
                    type="text"
                    v-model="$v.newCall.incidentLocation.$model"
                    :model="$v.newCall.incidentLocation"
                    :error="fieldsErrors.incidentLocation"
                    :onlyBooleanError="true"
                  ></CustomInput>
                </div>
                <div class="call-type select row">
                  <vSelect
                    class="custom-select primary"
                    v-model="newCall.type"
                    :reduce="type => type.value"
                    :disabled="true"
                    :clearable="false"
                    :selectable="option => option.value.includes('POLICE')"
                    :options="[
                      { label: $t('dispatch.unit-type.police'), value: 'POLICE' },
                      { label: $t('dispatch.unit-type.paramedic'), value: 'PARAMEDIC' },
                      { label: $t('dispatch.unit-type.firefighter'), value: 'FIREFIGHTER' }
                    ]"
                  ></vSelect>
                </div>
                <div class="category input row">
                  <CustomInput
                    class="custom-input primary col-pc-12"
                    inputClasses="box col-pc-12"
                    :placeholder="$t('dispatch.call-category')"
                    type="text"
                    v-model="$v.newCall.category.$model"
                    :model="$v.newCall.category"
                    :error="fieldsErrors.category"
                    :onlyBooleanError="true"
                  ></CustomInput>
                </div>
                <div class="priority select row">
                  <vSelect
                    class="custom-select primary"
                    v-model="newCall.priority"
                    :reduce="priority => priority.value"
                    :clearable="false"
                    :options="[
                      { label: $t('dispatch.call-priority.code-1'), value: 1 },
                      { label: $t('dispatch.call-priority.code-2'), value: 2 },
                      { label: $t('dispatch.call-priority.code-3'), value: 3 }
                    ]"
                  ></vSelect>
                </div>
              </div>
              <div class="column">
                <div class="description">
                  <textarea
                    class="uk-textarea primary"
                    :placeholder="$t('dispatch.call-description')"
                    v-model="$v.newCall.description.$model"
                    :class="{ orange: fieldsErrors.description }"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="buttons col-pc-12">
                    <button
                      class="col-pc-6 primary"
                      @click="clearNewCall"
                    >{{ $t('actions.cancel') }}</button>
                    <button
                      class="col-pc-6 primary-full"
                      :disabled="$v.$invalid || $store.getters['subscriptionPlanEnforcement/hasReachedMaxActiveCalls']"
                      :uk-tooltip="
                        $store.getters['subscriptionPlanEnforcement/hasReachedMaxActiveCalls']
                          ? `title: ${$t('dispatch.has-reached-max-active-calls')}; cls: uk-active`
                          : `title: ; cls: col-display-none`
                      "
                      @click="createCallCard"
                    >
                      {{ $t('actions.create') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="units-interface v-step-status-section">
          <div class="away">
            <h3 class="title">{{ $t('dispatch.unit-status.away') }}</h3>
            <div class="table">
              <div class="tr header">
                <div
                  class="th vehicle"
                  :class="{ active: vehiclesSort.away === 'number' }"
                  @click="sortVehiclesBy('number', 'away')"
                >
                  {{ $t('dispatch.vehicle') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.away === 'asc' && vehiclesSort.away === 'number'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.away === 'desc' && vehiclesSort.away === 'number'"
                  ></i>
                </div>
                <div
                  class="th role"
                  :class="{ active: vehiclesSort.away === 'role' }"
                  @click="sortVehiclesBy('role', 'away')"
                >
                  {{ $t('dispatch.role') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.away === 'asc' && vehiclesSort.away === 'role'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.away === 'desc' && vehiclesSort.away === 'role'"
                  ></i>
                </div>
                <div
                  class="th rank"
                  :class="{ active: vehiclesSort.away === 'rank' }"
                  @click="sortVehiclesBy('rank', 'away')"
                >
                  {{ $t('dispatch.rank') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.away === 'asc' && vehiclesSort.away === 'rank'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.away === 'desc' && vehiclesSort.away === 'rank'"
                  ></i>
                </div>
              </div>
              <div
                class="tr"
                v-for="unit in awayUnits"
                :key="unit.id"
                :class="{ panic: unit.panicEnabled }"
                @click="openUnitDetails(unit.id)"
              >
                <div class="td vehicle">
                  <span :title="unit.number">{{ unit.number }}</span>
                </div>
                <div class="td role">
                  <span>{{ capitalize(unit.type) }}</span>
                </div>
                <div class="td rank">
                  <span>{{ unit.rank ? unit.rank : $t('dispatch.unit-rank.patrol-unit') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="available">
            <h3 class="title">{{ $t('dispatch.unit-status.available') }}</h3>
            <div class="table">
              <div class="tr header">
                <div
                  class="th vehicle"
                  :class="{ active: vehiclesSort.available === 'number' }"
                  @click="sortVehiclesBy('number', 'available')"
                >
                  {{ $t('dispatch.vehicle') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.available === 'asc' && vehiclesSort.available === 'number'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.available === 'desc' && vehiclesSort.available === 'number'"
                  ></i>
                </div>
                <div
                  class="th role"
                  :class="{ active: vehiclesSort.available === 'role' }"
                  @click="sortVehiclesBy('role', 'available')"
                >
                  {{ $t('dispatch.role') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.available === 'asc' && vehiclesSort.available === 'role'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.available === 'desc' && vehiclesSort.available === 'role'"
                  ></i>
                </div>
                <div
                  class="th rank"
                  :class="{ active: vehiclesSort.available === 'rank' }"
                  @click="sortVehiclesBy('rank', 'available')"
                >
                  {{ $t('dispatch.rank') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.available === 'asc' && vehiclesSort.available === 'rank'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.available === 'desc' && vehiclesSort.available === 'rank'"
                  ></i>
                </div>
              </div>
              <div
                class="tr"
                v-for="unit in availableUnits"
                :key="unit.id"
                :class="{ panic: unit.panicEnabled }"
                @click="openUnitDetails(unit.id)"
              >
                <div class="td vehicle">
                  <span :title="unit.number">{{ unit.number }}</span>
                </div>
                <div class="td role">
                  <span>{{ capitalize(unit.type) }}</span>
                </div>
                <div class="td rank">
                  <span>{{ unit.rank ? unit.rank : $t('dispatch.unit-rank.patrol-unit') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="unavailable">
            <div class="header">
              <h3 class="title">{{ $t('dispatch.unit-status.unavailable') }}</h3>
              <div
                class="toggle-off-duty-units"
                :uk-tooltip="$t('dispatch.toggle-off-duty-units')"
              >
                <toggle-button
                  id="toggle-off-duty-units"
                  :sync="true"
                  v-model="showOffDutyUnits"
                  :labels="{
                    checked: $t('panic-buttons-dispatch_component.on'),
                    unchecked: $t('panic-buttons-dispatch_component.off')
                  }"
                  @change="updateUnavailableUnits"
                ></toggle-button>
              </div>
            </div>
            <div class="table">
              <div class="tr header">
                <div
                  class="th vehicle"
                  :class="{ active: vehiclesSort.unavailable === 'number' }"
                  @click="sortVehiclesBy('number', 'unavailable')"
                >
                  {{ $t('dispatch.vehicle') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.unavailable === 'asc' && vehiclesSort.unavailable === 'number'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.unavailable === 'desc' && vehiclesSort.unavailable === 'number'"
                  ></i>
                </div>
                <div
                  class="th role"
                  :class="{ active: vehiclesSort.unavailable === 'role' }"
                  @click="sortVehiclesBy('role', 'unavailable')"
                >
                  {{ $t('dispatch.role') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.unavailable === 'asc' && vehiclesSort.unavailable === 'role'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.unavailable === 'desc' && vehiclesSort.unavailable === 'role'"
                  ></i>
                </div>
                <div
                  class="th rank"
                  :class="{ active: vehiclesSort.unavailable === 'rank' }"
                  @click="sortVehiclesBy('rank', 'unavailable')"
                >
                  {{ $t('dispatch.rank') }}
                  <i
                    class="fas fa-long-arrow-alt-down"
                    v-if="vehiclesSortDir.unavailable === 'asc' && vehiclesSort.unavailable === 'rank'"
                  ></i>
                  <i
                    class="fas fa-long-arrow-alt-up"
                    v-if="vehiclesSortDir.unavailable === 'desc' && vehiclesSort.unavailable === 'rank'"
                  ></i>
                </div>
              </div>
              <div
                class="tr"
                v-for="unit in unavailableUnits"
                :key="unit.id"
                :class="{ panic: unit.panicEnabled }"
                @click="openUnitDetails(unit.id)"
              >
                <div class="td vehicle">
                  <span :title="unit.number">{{ unit.number }}</span>
                </div>
                <div class="td role">
                  <span>{{ capitalize(unit.type) }}</span>
                </div>
                <div class="td rank">
                  <span>{{ unit.rank ? unit.rank : $t('dispatch.unit-rank.patrol-unit') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-tour
      :name="this.$route.name"
      :steps="tourSteps"
      :callbacks="finishCallbackOnly"
      :options="translatedLabels"
    ></v-tour>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/dispatch.less"></style>

<script>
import UnloadHelper from '@/mixins/UnloadHelper'
import EnforcePermissionsForCommunity from '@/mixins/EnforcePermissionsForCommunity'
import TourManager from '@/mixins/TourManager'
import InformationBanner from '@/components/InformationBanner'
import CookieBanner from '@/components/CookieBanner'
import SearchVehicle from '@/components/SearchVehicle'
import SearchPerson from '@/components/SearchPerson'
import BroadcastPopup from '@/components/BroadcastPopup'
import PanicButtonsDispatch from '@/components/PanicButtonsDispatch'
import ArchivesDispatch from '@/components/ArchivesDispatch'
import CallDetailsPopup from '@/components/CallDetailsPopup'
import Violation from '@/components/Violation'
import Arrestation from '@/components/Arrestation'
import UnitDetails from '@/components/UnitDetails'
import { capitalize } from 'lodash'
import { mapGetters } from 'vuex'
import validationMessage from '@/validationMapping'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'dispatch',
  mixins: [UnloadHelper, EnforcePermissionsForCommunity, TourManager],
  components: {
    InformationBanner,
    CookieBanner,
    SearchVehicle,
    SearchPerson,
    BroadcastPopup,
    PanicButtonsDispatch,
    ArchivesDispatch,
    CallDetailsPopup,
    UnitDetails,
    Arrestation,
    Violation
  },
  data() {
    return {
      isLoading: false,
      fieldsErrors: {},
      openedCallId: '',
      openedUnitId: '',
      newCall: {
        callerName: '',
        callerPhone: '',
        incidentLocation: '',
        type: 'POLICE',
        category: '',
        priority: 1,
        description: '',
        dispatcher: { connect: { id: this.$store.state.selection.character.id } }
      },
      initialNewCall: null,
      activeCalls: [],
      callsSort: 'id',
      callsSortDir: 'asc',
      units: [],
      vehiclesSort: {
        away: 'number',
        available: 'number',
        unavailable: 'number'
      },
      vehiclesSortDir: {
        away: 'asc',
        available: 'asc',
        unavailable: 'asc'
      },
      openedArrestationId: null,
      openedCitationId: null,
      showOffDutyUnits: false,
      tourSteps: [
        {
          target: '.v-step-active-calls',
          content: this.$t('tour.dispatch.active-calls'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-create-call',
          content: this.$t('tour.dispatch.create-call'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-status-section',
          content: this.$t('tour.dispatch.status-section'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-vehicle-search',
          content: this.$t('tour.dispatch.vehicle-search-1'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-vehicle-search',
          content: this.$t('tour.dispatch.vehicle-search-2'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-name-search',
          content: this.$t('tour.dispatch.name-search'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-broadcast',
          content: this.$t('tour.dispatch.broadcast'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-panic',
          content: this.$t('tour.dispatch.panic'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-archives',
          content: this.$t('tour.dispatch.archives'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-logout',
          content: this.$t('tour.dispatch.logout'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        }
      ]
    }
  },
  validations: {
    newCall: {
      callerName: {},
      callerPhone: {},
      incidentLocation: {
        required
      },
      category: {
        required
      },
      description: {
        required
      }
    }
  },
  apollo: {
    activeCalls: {
      query: require('@/graphql/dispatcher/fetchCalls.gql'),
      fetchPolicy: 'network-only',
      variables: {
        status: ['OPENED', 'PENDING']
      },
      update(data) {
        this.$store.dispatch('subscriptionPlanEnforcement/updateActiveCallsCount', data.callCards.length)
        this.$logRocket.debug('[Query] Calls', data)
        return data.callCards
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToCalls.gql'),
        updateQuery(previousResult, { subscriptionData }) {
          const callCard = subscriptionData.data.callCard
          this.$logRocket.debug('[Subscription] Calls', subscriptionData)

          if (callCard.mutation === 'CREATED') {
            return {
              callCards: [...previousResult.callCards, callCard.node]
            }
          }

          if (callCard.mutation === 'UPDATED') {
            if (callCard.node.status === 'CLOSED') {
              return {
                callCards: previousResult.callCards.filter(card => card.id !== callCard.node.id)
              }
            }
            if (callCard.node.status === 'OPENED' && callCard.previousValues.status === 'CLOSED') {
              return {
                callCards: [...previousResult.callCards, callCard.node]
              }
            }
            return {
              callCards: previousResult.callCards.map(card => (card.id === callCard.node.id ? callCard.node : card))
            }
          }

          if (callCard.mutation === 'DELETED') {
            return {
              callCards: previousResult.callCards.filter(card => card.id !== callCard.previousValues.id)
            }
          }
        }
      }
    },
    units: {
      query: require('@/graphql/dispatcher/fetchUnits.gql'),
      fetchPolicy: 'network-only',
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id,
          onDuty: !this.showOffDutyUnits
        }
      },
      update(data) {
        this.$logRocket.debug('[Query] Units', data)
        return data.units
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToUnits.gql'),
        variables() {
          return {
            communityId: this.$store.state.selection.community.community.id
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const unit = subscriptionData.data.unit
          this.$logRocket.debug('[Subscription] Units', subscriptionData)

          // Show unit in panic notif
          if (unit.node.panicEnabled) {
            this.$notify({
              type: 'warn',
              title: this.$t('notifications.unit-in-panic.title'),
              text: this.$t('notifications.unit-in-panic.text', { unitNumber: unit.node.number }),
              duration: -1
            })
          }

          if (!unit.previousValues.onDuty && unit.node.onDuty) {
            if (this.showOffDutyUnits) {
              return {
                units: previousResult.units.filter(aUnit => aUnit.id !== unit.previousValues.id)
              }
            } else {
              const units = previousResult.units
              units.push(unit.node)

              return {
                units: units
              }
            }
          }

          if (unit.previousValues.onDuty && !unit.node.onDuty) {
            if (this.showOffDutyUnits) {
              const units = previousResult.units
              units.push(unit.node)

              return {
                units: units
              }
            } else {
              return {
                units: previousResult.units.filter(aUnit => aUnit.id !== unit.previousValues.id)
              }
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapGetters('popup', ['popupData']),
    capitalize(string) {
      return capitalize(string)
    },
    sortCallsBy: function(item) {
      // if item == current sort then reverse it
      if (item === this.callsSort) {
        this.callsSortDir = this.callsSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.callsSort = item
    },
    sortVehiclesBy: function(item, type) {
      // if item == current sort then reverse it
      if (item === this.vehiclesSort[type]) {
        this.vehiclesSortDir[type] = this.vehiclesSortDir[type] === 'asc' ? 'desc' : 'asc'
      }
      this.vehiclesSort[type] = item
    },
    clearNewCall() {
      this.newCall = Object.assign({}, this.initialNewCall)
    },
    createCallCard() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          incidentLocation: validationMessage(this.$v.newCall.incidentLocation),
          category: validationMessage(this.$v.newCall.category),
          description: validationMessage(this.$v.newCall.description)
        }
        this.isLoading = false
        return
      }

      this.$store
        .dispatch('dispatcher/createCallCard', this.newCall)
        .then(response => {
          this.isLoading = false

          this.clearNewCall()
          this.$notify({
            type: 'success',
            title: this.$t('notifications.call-card-created.title'),
            text: this.$t('notifications.call-card-created.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    updateUnavailableUnits() {
      this.$apollo.queries.units.refetch()
    },
    openCallDetails(callId) {
      this.openedCallId = callId
      this.$store.dispatch('popup/open', { page: 'dispatch', popup: 'callDetails' })
    },
    openUnitDetails(unitId) {
      this.openedUnitId = unitId
      this.$store.dispatch('popup/open', { page: 'dispatch', popup: 'unitDetails' })
    },
    openArrestationReport(arrestationId) {
      this.openedArrestationId = arrestationId
      this.$store.dispatch('popup/open', { page: 'dispatch', popup: 'arrestation-report-viewer' })
    },
    openViolationReport(citationId) {
      this.openedCitationId = citationId
      this.$store.dispatch('popup/open', { page: 'dispatch', popup: 'violation-report-viewer' })
    },
    searchCivilian(civilianId) {
      this.$store.dispatch('popup/open', { page: 'dispatch', popup: 'searchPerson' })
      this.$refs.searchPerson.openCivilian(civilianId)
    }
  },
  computed: {
    sortedCalls: function() {
      return this.activeCalls.slice().sort((a, b) => {
        let modifier = 1
        if (this.callsSortDir === 'desc') modifier = -1

        if (a[this.callsSort] < b[this.callsSort]) return -1 * modifier
        if (a[this.callsSort] > b[this.callsSort]) return 1 * modifier

        return 0
      })
    },
    unavailableUnits() {
      return this.units.filter(unit => !unit.status || unit.status.category === 'UNAVAILABLE')
    },
    availableUnits() {
      return this.units.filter(unit => unit.status && unit.status.category === 'AVAILABLE')
    },
    awayUnits() {
      return this.units.filter(unit => unit.status && unit.status.category === 'AWAY')
    }
  },
  mounted() {
    this.initialNewCall = Object.assign({}, this.newCall)
  }
}
</script>
