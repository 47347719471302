<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="panic_buttons-dispatch default"
  >
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto">
      <div class="panic_buttons-dispatch">
        <div class="main-form">
          <div class="frame">
            <div class="row">
              <div class="table">
                <div class="tr">
                  <div class="th col-pc-9">
                    <p>{{ $t('panic-buttons-dispatch_component.badge-number') }}</p>
                  </div>
                  <div class="th col-pc-3">
                    <p>{{ $t('panic-buttons-dispatch_component.status') }}</p>
                  </div>
                </div>
                <div class="tr" v-for="unit in units" :key="unit.id">
                  <div class="td col-pc-9">
                    <p>{{ unit.number }}</p>
                  </div>
                  <div class="td col-pc-3">
                    <toggle-button
                      :sync="true"
                      :value="unit.panicEnabled"
                      @change="togglePanic(unit)"
                      id="unit-panic-status"
                      :labels="{
                        checked: $t('panic-buttons-dispatch_component.on'),
                        unchecked: $t('panic-buttons-dispatch_component.off')
                      }"
                    ></toggle-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import Popup from '@/components/Popup.vue'

export default {
  components: {
    Popup
  },
  data() {
    return {
      units: []
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean
  },
  apollo: {
    units: {
      query: require('@/graphql/dispatcher/fetchUnits.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      }
      // Note: The subscription is not component dependant, it is then done on the dispatch page
    }
  },
  methods: {
    togglePanic(unit) {
      this.$store.dispatch('dispatcher/togglePanicForUnit', unit).catch(error => {
        this.$store.dispatch('errorManager/showError', { error: error })
      })
    }
  }
}
</script>
