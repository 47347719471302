<template>
  <div
    class="search col-pc-12 col-lgmobile-10 col-margin-auto"
    v-click-outside="
      () => {
        showResults = false
      }
    "
  >
    <div class="searchbar">
      <div class="input-icon secondary col-pc-12 col-lgmobile-12">
        <i class="fas fa-search"></i>
        <input
          class="box col-pc-12 col-lgmobile-12"
          type="text"
          @input="debounceSearch"
          @focus="searchQuery !== '' ? (showResults = true) : (showResults = false)"
          v-model.trim="searchQuery"
        />
        <i style="margin: auto;" class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>
      </div>
    </div>
    <div class="searchbar-results col-pc-12 col-lgmobile-12" v-if="showResults">
      <ul>
        <li v-show="searchResults.length === 0">{{ $t('search-unit_component.no-result') }}</li>
        <li
          v-for="searchResult in searchResults.filter(result => !excludedResults.includes(result.id))"
          :key="searchResult.item.id"
          @click="selectUnit(searchResult.item.id)"
        >
          {{ searchResult.item.number }}
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import { debounce } from 'lodash'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'UnitSearchInput',
  data() {
    return {
      isLoading: false,
      searchQuery: '',
      searchResults: [],
      showResults: false,
      unit: null
    }
  },
  props: {
    id: String,
    excludedResults: {
      type: Array,
      defaultValue: []
    },
    unitType: {
      type: String,
      defaultValue: 'POLICE'
    }
  },
  methods: {
    selectUnit: function(unitId) {
      this.showResults = false

      this.$store
        .dispatch('dispatcher/fetchUnitForSearch', unitId)
        .then(response => {
          this.unit = response
          this.searchQuery = ''

          this.$emit('selectedUnit', response)
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    debounceSearch: debounce(function(showResults = true) {
      if (this.searchQuery !== '') {
        this.isLoading = true
        this.$store
          .dispatch('dispatcher/searchUnits', {
            searchString: this.searchQuery,
            where: { onDuty: true, status: { category: 'AVAILABLE' }, type: this.unitType }
          })
          .then(response => {
            this.isLoading = false

            this.searchResults = response
            this.showResults = showResults
          })
      }
    }, 200),
    clearInput() {
      // Should be called by a parent component
      this.searchQuery = ''
    }
  },
  mounted() {
    if (this.id) {
      this.selectUnit(this.id)
      this.debounceSearch(false)
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
