import { render, staticRenderFns } from "./Dispatch.vue?vue&type=template&id=5b2420e1&scoped=true&"
import script from "./Dispatch.vue?vue&type=script&lang=js&"
export * from "./Dispatch.vue?vue&type=script&lang=js&"
import style0 from "@/assets/less/dashboards/dispatch.less?vue&type=style&index=0&id=5b2420e1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2420e1",
  null
  
)

export default component.exports