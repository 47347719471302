var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Popup',{staticClass:"unit-dispatch default",attrs:{"title":_vm.title,"page":_vm.page,"name":_vm.name,"parent":_vm.parent,"width":_vm.width,"height":_vm.height}},[(_vm.unit)?_c('div',{staticClass:"_content col-pc-10 col-lgmobile-10 col-margin-auto"},[_c('div',{staticClass:"unit-dispatch"},[_c('div',{staticClass:"main-form"},[_c('div',{staticClass:"frame"},[_c('div',{staticClass:"_top col-f"},[_c('div',{staticClass:"left col-all-4"}),_c('div',{staticClass:"center col-all-4"},[_c('h2',{staticClass:"unit_number"},[_vm._v(_vm._s(_vm.$t('unit-popup_component.title'))+" #"+_vm._s(_vm.unit.number))])]),_c('div',{staticClass:"right col-all-4"},[(_vm.unit.onDuty)?_c('button',{staticClass:"primary",on:{"click":_vm.offDutyUnit}},[_vm._v(" "+_vm._s(_vm.$t('unit-popup_component.off-duty'))+" ")]):_vm._e(),(!_vm.unit.onDuty)?_c('button',{staticClass:"primary",on:{"click":_vm.onDutyUnit}},[_vm._v(" "+_vm._s(_vm.$t('unit-popup_component.on-duty'))+" ")]):_vm._e()])]),_c('div',{staticClass:"rows col-pc-12 col-f"},[_c('div',{staticClass:"row left-row col-pc-4"},[_c('div',{staticClass:"columns col-pc-11"},[_c('div',{staticClass:"column"},[_c('h3',[_vm._v(_vm._s(_vm.$t('dispatch.role')))]),_c('vSelect',{staticClass:"custom-select primary box col-pc-12",attrs:{"reduce":type => type.value,"disabled":true,"clearable":false,"selectable":option => option.value.includes('POLICE'),"options":[
                      { label: _vm.$t('dispatch.unit-type.police'), value: 'POLICE' },
                      { label: _vm.$t('dispatch.unit-type.firefighter'), value: 'FIREFIGHTER' },
                      { label: _vm.$t('dispatch.unit-type.paramedic'), value: 'PARAMEDIC' }
                    ]},model:{value:(_vm.unit.type),callback:function ($$v) {_vm.$set(_vm.unit, "type", $$v)},expression:"unit.type"}})],1),_c('div',{staticClass:"column"},[_c('h3',[_vm._v(_vm._s(_vm.$t('dispatch.rank')))]),_c('vSelect',{staticClass:"custom-select primary box col-pc-12",attrs:{"reduce":rank => rank.value,"disabled":true,"clearable":false,"value":"PATROL_UNIT","options":[{ label: _vm.$t('dispatch.unit-rank.patrol-unit'), value: 'PATROL_UNIT' }]}})],1),_c('div',{staticClass:"column"},[_c('h3',[_vm._v(_vm._s(_vm.$t('dispatch.status')))]),(_vm.unit.status)?_c('vSelect',{staticClass:"custom-select primary box col-pc-12",attrs:{"reduce":status => status.value,"clearable":false,"options":_vm.mappedStatuses},on:{"input":_vm.updateUnitStatus},model:{value:(_vm.unit.status.id),callback:function ($$v) {_vm.$set(_vm.unit.status, "id", $$v)},expression:"unit.status.id"}}):_c('p',[_vm._v(_vm._s(_vm.$t('unit-popup_component.never-used')))])],1)])]),_c('div',{staticClass:"row right-row col-pc-8",staticStyle:{"z-index":"100"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column form-box col-pc-12"},[_c('div',{staticClass:"seats-number col-f"},[_c('div',{staticClass:"col-pc-6"},[_c('h3',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(_vm.$t('unit-popup_component.seats')))])]),_c('div',{staticClass:"col-pc-6 uk-text-right col-f"},[_c('h3',[_vm._v(_vm._s(_vm.unit.seats.length)+" / "+_vm._s(_vm.unit.maxSeats))])])]),_c('div',{staticClass:"allSeats"},[_c('table',_vm._l((_vm.unit.seats),function(seatedOfficer){return _c('tr',{key:seatedOfficer.id,staticClass:"seat"},[_c('p',{staticClass:"col-pc-4"},[_vm._v(_vm._s(seatedOfficer.badgeNumber))]),_c('p',{staticClass:"col-pc-4",attrs:{"uk-tooltip":_vm.checkPermissions()(_vm.permissions, 'admin')
                              ? `title: ${seatedOfficer.civilian.creator.email}; cls: uk-active`
                              : `title: ; cls: col-display-none`},on:{"click":function($event){return _vm.moveOfficer(seatedOfficer)}}},[_vm._v(" "+_vm._s(`${seatedOfficer.civilian.firstName} ${seatedOfficer.civilian.lastName}`)+" ")]),_c('a',{staticClass:"col-pc-4",on:{"click":function($event){return _vm.expulseOfficer(seatedOfficer.id)}}},[_vm._v(_vm._s(_vm.$t('unit-popup_component.actions.expulse')))])])}),0)])]),_c('div',{staticClass:"column"},[_c('h3',{staticClass:"active-calls-title"},[_vm._v(_vm._s(_vm.$t('unit-details_component.assigned-call')))]),_c('div',{staticClass:"active-calls"},[(!_vm.unit.activeCall)?_c('CallSearchInput',{attrs:{"excludedResults":_vm.unit.activeCall ? [_vm.unit.activeCall] : []},on:{"selectedCall":_vm.assignCall}}):_vm._e(),_c('ul',{staticClass:"col-pc-12"},[(_vm.unit.activeCall)?_c('li',[_c('div',{staticClass:"main-content col-f"},[_c('div',{staticClass:"text col-pc-9"},[_c('div',{staticClass:"call col-f col-pc-12"},[_c('p',{staticClass:"id col-pc-3 uk-text-left"},[_vm._v(_vm._s(_vm.unit.activeCall.sequentialId))]),_c('p',{staticClass:"category col-pc-8 uk-text-center",attrs:{"title":_vm.unit.activeCall.category}},[_vm._v(" "+_vm._s(_vm.unit.activeCall.category)+" ")]),_c('div',{staticClass:"blank col-pc-1"})])]),_c('div',{staticClass:"call-buttons col-pc-3 col-f"},[_c('button',{staticClass:"delete orange-full col-pc-6",on:{"click":_vm.unassignCall}},[_c('i',{staticClass:"fas fa-minus"})])])])]):_vm._e()])],1)])])])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }