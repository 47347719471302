<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="broadcast-popup default"
  >
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto">
      <div class="broadcast-popup">
        <div class="main-form">
          <div class="frame">
            <div class="_top">
              <div class="buttons">
                <button
                  class="primary uk-margin-small-right"
                  :class="{ active: currentTab == 'bolos' }"
                  @click="currentTab = 'bolos'"
                >
                  {{ $t('broadcast-popup_component.bolos') }}
                </button>
                <button
                  class="primary"
                  :class="{ active: currentTab == 'broadcastMessage' }"
                  @click="currentTab = 'broadcastMessage'"
                >
                  {{ $t('broadcast-popup_component.message') }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="bolos" v-if="currentTab === 'bolos'">
                <div class="bolos-list" v-if="!isEditingBolo && !isAddingBolo">
                  <ul>
                    <li v-for="personBolo in personBolos" :key="personBolo.id">
                      <div class="text col-pc-8">
                        <p
                          :uk-tooltip="
                            'title: <b>' +
                              $t('broadcast-popup_component.person-description') +
                              ':</b> ' +
                              personBolo.description +
                              ' <br /> <b>' +
                              $t('broadcast-popup_component.reason') +
                              ':</b> ' +
                              personBolo.reason
                          "
                          class="reason col-pc-10-important"
                        >
                          {{ personBolo.description }}
                        </p>
                        <p class="type col-pc-4-important">({{ $t('broadcast-popup_component.person') }})</p>
                      </div>
                      <div class="buttons col-pc-4">
                        <button class="warning-full col-pc-6" @click="setEditBolo(personBolo, 'PERSON')">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="danger-full col-pc-6" @click="deleteBolo(personBolo.id, 'PERSON')">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </li>
                    <li v-for="vehicleBolo in vehicleBolos" :key="vehicleBolo.id">
                      <div class="text col-pc-8">
                        <p
                          :uk-tooltip="
                            'title: <b>' +
                              $t('broadcast-popup_component.person-description') +
                              ':</b> ' +
                              vehicleBolo.description +
                              ' <br /> <b>' +
                              $t('broadcast-popup_component.reason') +
                              ':</b> ' +
                              vehicleBolo.reason
                          "
                          class="reason col-pc-10-important"
                        >
                          {{ vehicleBolo.description }}
                        </p>
                        <p class="type col-pc-4-important">({{ $t('broadcast-popup_component.vehicle') }})</p>
                      </div>
                      <div class="buttons col-pc-4">
                        <button class="warning-full col-pc-6" @click="setEditBolo(vehicleBolo, 'VEHICLE')">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="danger-full col-pc-6" @click="deleteBolo(vehicleBolo.id, 'VEHICLE')">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </li>
                    <li v-if="personBolos.length + vehicleBolos.length === 0">
                      <div class="no-bolos col-pc-12">
                        <p class="uk-text-center col-pc-12-important">{{ $t('broadcast-popup_component.no-bolos') }}</p>
                      </div>
                    </li>
                  </ul>
                  <button class="primary-full col-all-12" @click="setAddNewBolo">{{ $t('actions.add') }}</button>
                </div>
                <div class="form" v-if="isEditingBolo || isAddingBolo">
                  <div class="tr">
                    <div class="td col-pc-12 col-lgmobile-12">
                      <p>{{ $t('broadcast-popup_component.bolo-type') }}</p>
                      <vSelect
                        :clearable="false"
                        v-model="newBolo.type"
                        :disabled="isEditingBolo"
                        :options="[
                          { label: $t('broadcast-popup_component.person'), value: 'PERSON' },
                          { label: $t('broadcast-popup_component.vehicle'), value: 'VEHICLE' }
                        ]"
                        :reduce="type => type.value"
                        class="custom-select blank uppercase"
                      ></vSelect>
                    </div>
                  </div>
                  <div class="tr" v-if="newBolo.type === 'PERSON'">
                    <div class="td col-pc-12 col-lgmobile-6">
                      <div class="person full-name">
                        <p>{{ $t('broadcast-popup_component.person-full-name') }}</p>
                        <CustomInput
                          class="col-all-12 custom-input primary uppercase"
                          inputClasses="col-all-12 blank"
                          type="text"
                          :placeholder="$t('broadcast-popup_component.person-full-name_placeholder')"
                          v-model="$v.newBolo.person.name.$model"
                          :model="$v.newBolo.person.name"
                        ></CustomInput>
                      </div>
                    </div>
                  </div>
                  <div class="tr" v-if="newBolo.type === 'VEHICLE'">
                    <div class="td col-pc-12 col-lgmobile-12">
                      <div class="vehicle plate">
                        <p>{{ $t('broadcast-popup_component.vehicle-plate') }}</p>
                        <CustomInput
                          class="col-all-12 custom-input primary uppercase"
                          inputClasses="blank col-pc-12"
                          type="text"
                          :placeholder="$t('broadcast-popup_component.vehicle-plate_placeholder')"
                          v-model="$v.newBolo.vehicle.plate.$model"
                          :model="$v.newBolo.vehicle.plate"
                        ></CustomInput>
                      </div>
                    </div>
                  </div>
                  <div class="tr">
                    <div class="td col-pc-6 col-lgmobile-6">
                      <div class="person" v-if="newBolo.type === 'PERSON'">
                        <p>{{ $t('broadcast-popup_component.person-height') }}</p>
                        <CustomInput
                          class="col-all-12 custom-input primary uppercase"
                          inputClasses="blank col-pc-12"
                          type="text"
                          :placeholder="$t('broadcast-popup_component.person-height_placeholder')"
                          v-model.number="$v.newBolo.person.height.$model"
                          :model="$v.newBolo.person.height"
                        ></CustomInput>
                      </div>
                      <div class="vehicle" v-if="newBolo.type === 'VEHICLE'">
                        <p>{{ $t('broadcast-popup_component.vehicle-model') }}</p>
                        <CustomInput
                          class="col-all-12 custom-input primary uppercase"
                          inputClasses="blank col-pc-12"
                          type="text"
                          :placeholder="$t('broadcast-popup_component.vehicle-model_placeholder')"
                          v-model="$v.newBolo.vehicle.model.$model"
                          :model="$v.newBolo.vehicle.model"
                        ></CustomInput>
                      </div>
                    </div>
                    <div class="td col-pc-6 col-lgmobile-6">
                      <div class="person" v-if="newBolo.type === 'PERSON'">
                        <p>{{ $t('broadcast-popup_component.person-weight') }}</p>
                        <CustomInput
                          class="col-all-12 custom-input primary uppercase"
                          inputClasses="blank col-pc-12"
                          type="text"
                          :placeholder="$t('broadcast-popup_component.person-weight_placeholder')"
                          v-model.number="$v.newBolo.person.weight.$model"
                          :model="$v.newBolo.person.weight"
                        ></CustomInput>
                      </div>
                      <div class="vehicle" v-if="newBolo.type === 'VEHICLE'">
                        <p>{{ $t('broadcast-popup_component.vehicle-color') }}</p>
                        <vSelect
                          v-model="newBolo.vehicle.color"
                          :reduce="color => color.value"
                          class="custom-select blank uppercase col-pc-12"
                          :clearable="false"
                          :options="[
                            { label: $t('car-color-attribute.BLACK'), value: 'BLACK' },
                            { label: $t('car-color-attribute.BLUE'), value: 'BLUE' },
                            { label: $t('car-color-attribute.BROWN'), value: 'BROWN' },
                            { label: $t('car-color-attribute.YELLOW'), value: 'YELLOW' },
                            { label: $t('car-color-attribute.ORANGE'), value: 'ORANGE' },
                            { label: $t('car-color-attribute.PURPLE'), value: 'PURPLE' },
                            { label: $t('car-color-attribute.RED'), value: 'RED' },
                            { label: $t('car-color-attribute.GREEN'), value: 'GREEN' },
                            { label: $t('car-color-attribute.GREY'), value: 'GREY' },
                            { label: $t('car-color-attribute.WHITE'), value: 'WHITE' },
                            { label: $t('car-color-attribute.UNKNOWN'), value: 'UNKNOWN' }
                          ]"
                        ></vSelect>
                      </div>
                    </div>
                  </div>
                  <div class="tr" v-if="newBolo.type === 'PERSON'">
                    <div class="td col-pc-6 col-lgmobile-6">
                      <div class="person" v-if="newBolo.type === 'PERSON'">
                        <p>{{ $t('broadcast-popup_component.person-ethnicity') }}</p>
                        <vSelect
                          v-model="newBolo.person.ethnicity"
                          :reduce="ethnicity => ethnicity.value"
                          class="custom-select blank uppercase col-pc-12"
                          :clearable="false"
                          :options="[
                            { label: $t('ethnic-group-attribute.HISPANIC'), value: 'HISPANIC' },
                            { label: $t('ethnic-group-attribute.NATIVE'), value: 'NATIVE' },
                            { label: $t('ethnic-group-attribute.ASIAN'), value: 'ASIAN' },
                            { label: $t('ethnic-group-attribute.BLACK'), value: 'BLACK' },
                            { label: $t('ethnic-group-attribute.WHITE'), value: 'WHITE' },
                            { label: $t('ethnic-group-attribute.UNKNOWN'), value: 'UNKNOWN' }
                          ]"
                        ></vSelect>
                      </div>
                    </div>
                    <div class="td col-pc-6 col-lgmobile-6">
                      <div class="person" v-if="newBolo.type === 'PERSON'">
                        <p>{{ $t('broadcast-popup_component.person-gender') }}</p>
                        <vSelect
                          v-model="newBolo.person.gender"
                          :reduce="gender => gender.value"
                          class="custom-select uppercase blank col-all-12"
                          :clearable="false"
                          :options="[
                            { label: $t('gender-attribute.MALE'), value: 'MALE' },
                            { label: $t('gender-attribute.FEMALE'), value: 'FEMALE' },
                            { label: $t('gender-attribute.UNKNOWN'), value: 'UNKNOWN' }
                          ]"
                        ></vSelect>
                      </div>
                    </div>
                  </div>
                  <div class="tr" v-if="newBolo.type === 'PERSON'">
                    <div class="td col-pc-6 col-lgmobile-6">
                      <div class="person" v-if="newBolo.type === 'PERSON'">
                        <p>{{ $t('broadcast-popup_component.person-eye-color') }}</p>
                        <vSelect
                          v-model="newBolo.person.eyeColor"
                          :reduce="eyeColor => eyeColor.value"
                          class="custom-select uppercase blank col-all-12"
                          :clearable="false"
                          :options="[
                            { label: $t('eye-color-attribute.BROWN'), value: 'BROWN' },
                            { label: $t('eye-color-attribute.BLUE'), value: 'BLUE' },
                            { label: $t('eye-color-attribute.GREEN'), value: 'GREEN' },
                            { label: $t('eye-color-attribute.AMBER'), value: 'AMBER' },
                            { label: $t('eye-color-attribute.OTHER'), value: 'OTHER' },
                            { label: $t('eye-color-attribute.UNKNOWN'), value: 'UNKNOWN' }
                          ]"
                        ></vSelect>
                      </div>
                    </div>
                    <div class="td col-pc-6 col-lgmobile-6">
                      <div class="person" v-if="newBolo.type === 'PERSON'">
                        <p>{{ $t('broadcast-popup_component.person-hair-color') }}</p>
                        <vSelect
                          append-to-body
                          :calculate-position="withPopper"
                          v-model="newBolo.person.hairColor"
                          :reduce="hairColor => hairColor.value"
                          class="custom-select blank col-all-12 uppercase dropdown:line"
                          :clearable="false"
                          :options="[
                            { label: $t('hair-color-attribute.AUBURN'), value: 'AUBURN' },
                            { label: $t('hair-color-attribute.BLACK'), value: 'BLACK' },
                            { label: $t('hair-color-attribute.BLOND'), value: 'BLOND' },
                            { label: $t('hair-color-attribute.BROWN'), value: 'BROWN' },
                            { label: $t('hair-color-attribute.REDHAIR'), value: 'REDHAIR' },
                            { label: $t('hair-color-attribute.WHITE'), value: 'WHITE' },
                            { label: $t('hair-color-attribute.BALD'), value: 'BALD' },
                            { label: $t('hair-color-attribute.OTHER'), value: 'OTHER' },
                            { label: $t('hair-color-attribute.UNKNOWN'), value: 'UNKNOWN' }
                          ]"
                        ></vSelect>
                      </div>
                    </div>
                  </div>
                  <div class="tr">
                    <div class="td col-pc-12 col-lgmobile-12">
                      <div class="vehicle">
                        <p>
                          {{ $t('broadcast-popup_component.bolo-reason') }}
                          <span class="orange">*</span>
                        </p>
                        <CustomInput
                          class="custom-input primary col-pc-12 uppercase"
                          inputClasses="col-pc-12 blank"
                          type="text"
                          :placeholder="$t('broadcast-popup_component.bolo-reason_placeholder')"
                          v-model="$v.newBolo.reason.$model"
                          :model="$v.newBolo.reason"
                          :error="fieldsErrors.reason"
                          :onlyBooleanError="true"
                        ></CustomInput>
                      </div>
                    </div>
                  </div>
                  <div class="tr">
                    <div class="td col-pc-12 col-lgmobile-12">
                      <div class="person">
                        <p>
                          {{ $t('broadcast-popup_component.person-description') }}
                          <span class="orange">*</span>
                        </p>
                        <textarea
                          rows="5"
                          class="primary col-all-12"
                          :placeholder="$t('broadcast-popup_component.person-description_placeholder')"
                          v-model="$v.newBolo.description.$model"
                          :style="fieldsErrors.description ? 'color: #f2684a;' : ''"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="button col-pc-12">
                    <button class="primary" :disabled="isLoading" @click="cancelBolo">
                      {{ $t('actions.cancel') }}
                    </button>
                    <button
                      class="primary"
                      :class="{ spinner: isLoading }"
                      v-if="!isEditingBolo"
                      :disabled="$v.$invalid"
                      @click="createBolo"
                    >
                      {{ $t('actions.add') }}
                    </button>
                    <button
                      class="primary"
                      :class="{ spinner: isLoading }"
                      v-if="isEditingBolo"
                      :disabled="$v.$invalid"
                      @click="saveBolo"
                    >
                      {{ $t('actions.save') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="message" v-if="currentTab === 'broadcastMessage' && broadcastMessage">
                <form>
                  <div class="active">
                    <div class="left col-pc-6">
                      <p>{{ $t('broadcast-popup_component.active') }}</p>
                    </div>
                    <div class="right col-pc-6">
                      <toggle-button
                        v-model="broadcastMessage.active"
                        id="broadcast-message-toggle"
                        :labels="{
                          checked: $t('broadcast-popup_component.on'),
                          unchecked: $t('broadcast-popup_component.off')
                        }"
                      ></toggle-button>
                    </div>
                  </div>
                  <!-- <div class="preset">
                    <label for="preset">{{ $t('broadcast-popup_component.message-preset') }}</label>
                    <vSelect
                      v-model="broadcastMessageSelectedPreset"
                      :reduce="broadcastMessageSelectedPreset => broadcastMessageSelectedPreset.value"
                      class="custom-select primary font-weight-semibold uppercase col-pc-12"
                      :clearable="false"
                      :options="[
                        { label: $t('broadcast-popup_component.radio-priority'), value: 'RADIO_PRIORITY' },
                        { label: $t('broadcast-popup_component.custom'), value: 'CUSTOM' }
                      ]"
                    ></vSelect>
                  </div>-->
                  <div class="type">
                    <label for="type">{{ $t('broadcast-popup_component.message-type') }}</label>
                    <vSelect
                      v-model="broadcastMessage.type"
                      :reduce="type => type.value"
                      class="custom-select primary-full font-weight-semibold uppercase col-pc-12"
                      :class="{
                        success: broadcastMessage.type === 'SUCCESS',
                        warning: broadcastMessage.type === 'WARNING',
                        danger: broadcastMessage.type === 'DANGER'
                      }"
                      :clearable="false"
                      :options="[
                        { label: $t('broadcast-popup_component.sound-only'), value: 'NONE' },
                        { label: $t('broadcast-popup_component.danger'), value: 'DANGER' },
                        { label: $t('broadcast-popup_component.warning'), value: 'WARNING' },
                        { label: $t('broadcast-popup_component.success'), value: 'SUCCESS' }
                      ]"
                    ></vSelect>
                  </div>
                  <div class="sound">
                    <label for="sound">{{ $t('broadcast-popup_component.message-sound') }}</label>
                    <div class="primary col-f">
                      <vSelect
                        v-model="broadcastMessage.sound"
                        :reduce="sound => sound.value"
                        class="custom-select primary font-weight-semibold uppercase col-pc-12"
                        :clearable="false"
                        :options="[
                          { label: $t('broadcast-popup_component.none'), value: 'NONE' },
                          {
                            label: $t('broadcast-popup_component.tone-1'),
                            value: 'TONE_1'
                          },
                          { label: $t('broadcast-popup_component.tone-2'), value: 'TONE_2' },
                          { label: $t('broadcast-popup_component.tone-3'), value: 'TONE_3' },
                          { label: $t('broadcast-popup_component.radio-priority'), value: 'RADIO_PRIORITY' }
                        ]"
                      ></vSelect>
                      <a
                        @click="toggleSoundPreview"
                        class="play-sound"
                        :class="{ disabled: broadcastMessage.sound === 'NONE' }"
                        :uk-tooltip="$t('broadcast-popup_component.preview-sound')"
                      >
                        <i
                          class="fas"
                          :class="{
                            'fa-play': !playBroadcastMessageSound,
                            'fa-pause': playBroadcastMessageSound
                          }"
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div class="sound-repeat-interval">
                    <label for="sound-repeat-interval" class="col-f col-jfy-c-center">
                      {{ $t('broadcast-popup_component.message-sound-repeat-interval') }}
                      <div class="info uk-margin-small-left">
                        <i
                          class="fas fa-question-circle"
                          :uk-tooltip="
                            'title:' +
                              $t('broadcast-popup_component.message-sound-repeat-interval_tooltip') +
                              ';' +
                              'pos: right'
                          "
                        ></i>
                      </div>
                    </label>
                    <CustomInput
                      class="custom-input primary col-pc-12"
                      inputClasses="col-pc-12 box"
                      type="number"
                      v-model.number="$v.broadcastMessage.soundRepeatInterval.$model"
                      :model="$v.broadcastMessage.soundRepeatInterval"
                      :error="fieldsErrors.soundRepeatInterval"
                      name="sound-repeat-interval"
                    ></CustomInput>
                  </div>
                  <div class="text" v-if="broadcastMessage.type !== 'NONE'">
                    <label for="message-text">{{ $t('broadcast-popup_component.message-text') }}</label>
                    <CustomInput
                      class="custom-input primary col-pc-12"
                      inputClasses="col-pc-12 box"
                      type="text"
                      v-model="$v.broadcastMessage.text.$model"
                      :model="$v.broadcastMessage.text"
                      :placeholder="$t('broadcast-popup_component.message-text_placeholder')"
                      name="message-text"
                    ></CustomInput>
                  </div>
                  <div class="buttons col-f">
                    <div class="button col-pc-6 uk-margin-small-right">
                      <button class="primary col-pc-12" @click.prevent="resetBroadcastMessage">
                        {{ $t('actions.reset') }}
                      </button>
                    </div>
                    <div class="button col-pc-6">
                      <button class="primary-full col-pc-12" @click.prevent="saveBroadcastMessage">
                        {{ $t('actions.save') }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>
<style lang="less" scoped src="@/assets/less/components/reports.less"></style>

<script>
import Popup from '@/components/Popup'
import DropdownPositionCalculator from '@/mixins/DropdownPositionCalculator'
import validationMessage from '@/validationMapping'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  components: {
    Popup
  },
  mixins: [DropdownPositionCalculator],
  data() {
    return {
      fieldsErrors: {},
      isLoading: false,
      isAddingBolo: false,
      isEditingBolo: false,
      personBolos: [],
      vehicleBolos: [],
      newBolo: {
        type: 'PERSON',
        reason: '',
        description: '',
        vehicle: {
          plate: '',
          model: '',
          color: 'UNKNOWN'
        },
        person: {
          name: '',
          height: null,
          weight: null,
          ethnicity: 'UNKNOWN',
          gender: 'UNKNOWN',
          eyeColor: 'UNKNOWN',
          hairColor: 'UNKNOWN'
        }
      },
      initialNewBolo: null,
      currentTab: 'bolos',
      playBroadcastMessageSound: false,
      broadcastMessageSelectedPreset: 'CUSTOM',
      broadcastMessage: {
        id: ''
      },
      initialBroadcastMessage: {
        active: false,
        type: 'NONE',
        sound: 'NONE',
        soundRepeatInterval: 0,
        text: ''
      }
    }
  },
  validations: {
    newBolo: {
      reason: {
        required: requiredIf(function() {
          return this.currentTab === 'bolos'
        })
      },
      description: {
        required: requiredIf(function() {
          return this.currentTab === 'bolos'
        })
      },
      person: {
        name: {},
        height: {},
        weight: {}
      },
      vehicle: {
        plate: {},
        model: {}
      }
    },
    broadcastMessage: {
      soundRepeatInterval: {
        required: requiredIf(function() {
          return this.currentTab === 'broadcastMessage'
        })
      },
      text: {}
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean
  },
  apollo: {
    broadcastMessage: {
      query: require('@/graphql/broadcastMessage/fetchBroadcastMessage.gql'),
      subscribeToMore: {
        document: require('@/graphql/broadcastMessage/subscribeToBroadcastMessage.gql'),
        variables() {
          return {
            id: this.broadcastMessage.id
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const broadcastMessage = subscriptionData.data.broadcastMessage

          return {
            broadcastMessage: broadcastMessage.node
          }
        }
      }
    },
    personBolos: {
      query: require('@/graphql/dispatcher/fetchPersonBolos.gql'),
      update: data => data.personBoloes,
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToPersonBolos.gql'),
        updateQuery: (previousResult, { subscriptionData }) => {
          const personBolo = subscriptionData.data.personBolo

          if (personBolo.mutation === 'CREATED') {
            return {
              personBoloes: [...previousResult.personBoloes, personBolo.node]
            }
          }

          if (personBolo.mutation === 'UPDATED') {
            return {
              personBoloes: previousResult.personBoloes.map(bolo =>
                bolo.id === personBolo.node.id ? personBolo.node : bolo
              )
            }
          }

          if (personBolo.mutation === 'DELETED') {
            return {
              personBoloes: previousResult.personBoloes.filter(bolo => bolo.id !== personBolo.previousValues.id)
            }
          }
        }
      }
    },
    vehicleBolos: {
      query: require('@/graphql/dispatcher/fetchVehicleBolos.gql'),
      update: data => data.vehicleBoloes,
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToVehicleBolos.gql'),
        updateQuery: (previousResult, { subscriptionData }) => {
          const vehicleBolo = subscriptionData.data.vehicleBolo

          if (vehicleBolo.mutation === 'CREATED') {
            return {
              vehicleBoloes: [...previousResult.vehicleBoloes, vehicleBolo.node]
            }
          }

          if (vehicleBolo.mutation === 'UPDATED') {
            return {
              vehicleBoloes: previousResult.vehicleBoloes.map(bolo =>
                bolo.id === vehicleBolo.node.id ? vehicleBolo.node : bolo
              )
            }
          }

          if (vehicleBolo.mutation === 'DELETED') {
            return {
              vehicleBoloes: previousResult.vehicleBoloes.filter(bolo => bolo.id !== vehicleBolo.previousValues.id)
            }
          }
        }
      }
    }
  },
  methods: {
    createBolo() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          reason: validationMessage(this.$v.newBolo.reason),
          description: validationMessage(this.$v.newBolo.description)
        }
        this.isLoading = false
        return
      }

      this.$store.dispatch('dispatcher/createBolo', this.newBolo).then(response => {
        this.isLoading = false

        this.newBolo = Object.assign({}, this.initialNewBolo)
        this.fieldsErrors = {}
        this.isAddingBolo = false

        this.$notify({
          type: 'success',
          title: this.$t('notifications.save.title'),
          text: this.$t('notifications.save.text')
        })
      })
    },
    saveBolo() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          reason: validationMessage(this.$v.newBolo.reason),
          description: validationMessage(this.$v.newBolo.description)
        }
        this.isLoading = false
        return
      }

      this.$store.dispatch('dispatcher/updateBolo', this.newBolo).then(response => {
        this.isLoading = false

        this.newBolo = Object.assign({}, this.initialNewBolo)
        this.fieldsErrors = {}
        this.isEditingBolo = false

        this.$notify({
          type: 'success',
          title: this.$t('notifications.save.title'),
          text: this.$t('notifications.save.text')
        })
      })
    },
    setAddNewBolo() {
      this.newBolo = Object.assign({}, this.initialNewBolo, {
        person: this.initialNewBolo.person,
        vehicle: this.initialNewBolo.vehicle
      })
      this.fieldsErrors = {}
      this.isAddingBolo = true
    },
    setEditBolo(bolo, boloType) {
      this.newBolo = Object.assign({}, this.initialNewBolo, { [boloType.toLowerCase()]: bolo })
      this.fieldsErrors = {}
      this.newBolo.id = bolo.id
      this.newBolo.reason = bolo.reason
      this.newBolo.description = bolo.description
      this.newBolo.type = boloType

      this.isEditingBolo = true
    },
    cancelBolo() {
      this.newBolo = Object.assign({}, this.initialNewBolo)
      this.fieldsErrors = {}
      this.isEditingBolo = false
      this.isAddingBolo = false
    },
    deleteBolo(boloId, boloType) {
      this.$swal({
        title: this.$t('broadcast-popup_component.remove-bolo-dialog.title'),
        text: this.$t('broadcast-popup_component.remove-bolo-dialog.text'),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('actions.delete')
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('dispatcher/deleteBolo', { boloId, type: boloType }).catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
        }
      })
    },
    saveBroadcastMessage() {
      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          soundRepeatInterval: validationMessage(this.$v.broadcastMessage.soundRepeatInterval)
        }
        return
      }

      this.$store.dispatch('broadcastMessage/updateBroadcastMessage', this.broadcastMessage).then(() => {
        this.$notify({
          type: 'success',
          title: this.$t('notifications.save.title'),
          text: this.$t('notifications.save.text')
        })
      })
    },
    resetBroadcastMessage() {
      const id = this.broadcastMessage.id
      this.broadcastMessage = Object.assign({}, this.initialBroadcastMessage)
      this.broadcastMessage.id = id

      this.saveBroadcastMessage()
    },
    toggleSoundPreview() {
      if (this.broadcastMessage.sound === 'NONE' && this.playBroadcastMessageSound) {
        this.playBroadcastMessageSound = false
      }

      if (this.broadcastMessage.sound !== 'NONE') {
        this.playBroadcastMessageSound = !this.playBroadcastMessageSound
      }

      if (this.playBroadcastMessageSound && this.broadcastMessage.sound !== 'NONE') {
        switch (this.broadcastMessage.sound) {
          case 'TONE_1':
            this.soundPreviewAudioSource = new Audio('//cdn.leafcad.com/sounds/tone-1.mp3')
            break
          case 'TONE_2':
            this.soundPreviewAudioSource = new Audio('//cdn.leafcad.com/sounds/tone-2.mp3')
            break
          case 'TONE_3':
            this.soundPreviewAudioSource = new Audio('//cdn.leafcad.com/sounds/tone-3.mp3')
            break
          case 'RADIO_PRIORITY':
            this.soundPreviewAudioSource = new Audio('//cdn.leafcad.com/sounds/priority-tone.mp3')
            break
        }
        this.soundPreviewAudioSource.play()
        this.soundPreviewAudioSource.onended = () => {
          this.soundPreviewAudioSource = null
          this.playBroadcastMessageSound = false
        }
      } else if (!this.playBroadcastMessageSound) {
        if (this.soundPreviewAudioSource) {
          this.soundPreviewAudioSource.pause()
          this.soundPreviewAudioSource = null
        }
      }
    }
  },
  mounted() {
    this.initialNewBolo = Object.assign({}, this.newBolo)
  }
}
</script>
