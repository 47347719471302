<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="call-details-popup default"
  >
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto" v-if="callCard">
      <div class="call-details-popup">
        <div class="main-form">
          <div class="frame">
            <div class="row">
              <div class="column">
                <h2>{{ $t('call-details-popup_component.call') }} #{{ callCard.sequentialId }}</h2>
              </div>
            </div>
            <div class="row archived-warning" v-if="callCard.status === 'CLOSED'" style="margin-bottom: 15px;">
              <div class="banner orange error">
                <p>{{ $t('call-details-popup_component.call-is-archived') }}</p>
              </div>
            </div>
            <div class="row call-information-inputs-row">
              <div class="columns">
                <div class="column col-all-12">
                  <table class="information-inputs">
                    <tr class="caller_name _row">
                      <td class="input-label">
                        <label class="col-pc-12">{{ $t('call-details-popup_component.caller-name') }}</label>
                      </td>
                      <td>
                        <CustomInput
                          class="custom-input primary col-all-12"
                          inputClasses="col-all-12"
                          type="text"
                          v-model="$v.callCard.callerName.$model"
                          :model="$v.callCard.callerName"
                          :readonly="!isEditingCallCard"
                        ></CustomInput>
                      </td>
                    </tr>
                    <tr class="caller_phone _row">
                      <td class="input-label">
                        <label class="col-pc-12">{{ $t('call-details-popup_component.caller-phone') }}</label>
                      </td>
                      <td>
                        <CustomInput
                          class="custom-input primary col-all-12"
                          inputClasses="col-all-12"
                          type="text"
                          v-model="$v.callCard.callerPhone.$model"
                          :model="$v.callCard.callerPhone"
                          :readonly="!isEditingCallCard"
                        ></CustomInput>
                      </td>
                    </tr>
                    <tr class="location _row">
                      <td class="input-label">
                        <label class="col-pc-12">{{ $t('call-details-popup_component.call-location') }}</label>
                      </td>
                      <td>
                        <CustomInput
                          class="custom-input primary col-all-12"
                          inputClasses="col-all-12"
                          type="text"
                          v-model="$v.callCard.incidentLocation.$model"
                          :model="$v.callCard.incidentLocation"
                          :readonly="!isEditingCallCard"
                        ></CustomInput>
                      </td>
                    </tr>
                    <tr class="type _row">
                      <td class="input-label">
                        <label class="col-pc-12">{{ $t('call-details-popup_component.call-type') }}</label>
                      </td>
                      <td>
                        <vSelect
                          class="custom-select line col-all-12 primary"
                          v-model="callCard.type"
                          :reduce="type => type.value"
                          :clearable="false"
                          :disabled="!isEditingCallCard"
                          :selectable="option => option.value.includes('POLICE')"
                          :options="[
                            { label: $t('dispatch.unit-type.police'), value: 'POLICE' },
                            { label: $t('dispatch.unit-type.paramedic'), value: 'PARAMEDIC' },
                            { label: $t('dispatch.unit-type.firefighter'), value: 'FIREFIGHTER' }
                          ]"
                        ></vSelect>
                      </td>
                    </tr>
                    <tr class="category _row">
                      <td class="input-label">
                        <label class="col-pc-12">{{ $t('call-details-popup_component.call-category') }}</label>
                      </td>
                      <td class="col-all-12">
                        <CustomInput
                          class="custom-input primary col-pc-12"
                          inputClasses="col-pc-12"
                          type="text"
                          v-model="$v.callCard.category.$model"
                          :model="$v.callCard.category"
                          :readonly="!isEditingCallCard"
                        ></CustomInput>
                      </td>
                    </tr>
                    <tr class="priority _row">
                      <td class="input-label">
                        <label class="col-pc-12">{{ $t('call-details-popup_component.call-priority') }}</label>
                      </td>
                      <td class="col-all-12">
                        <vSelect
                          class="custom-select line primary col-pc-12"
                          v-model="callCard.priority"
                          :reduce="priority => priority.value"
                          :disabled="!isEditingCallCard"
                          :clearable="false"
                          :options="[
                            { label: $t('call-details-popup_component.code-1'), value: 1 },
                            { label: $t('call-details-popup_component.code-2'), value: 2 },
                            { label: $t('call-details-popup_component.code-3'), value: 3 }
                          ]"
                        ></vSelect>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="column col-all-12">
                  <div class="description _row col-all-12">
                    <label>{{ $t('call-details-popup_component.call-description') }}</label>
                    <textarea
                      class="primary col-pc-12"
                      v-model="callCard.description"
                      :readonly="!isEditingCallCard"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row call-logs-and-units-row">
              <div class="columns">
                <div class="column call-logs">
                  <p class="title">{{ $t('call-details-popup_component.call-logs') }}</p>
                  <div class="_wrap">
                    <div class="row">
                      <ul class="col-pc-12 col-lgmobile-12">
                        <li v-for="log in logs" :key="log.id" :class="{ 'owned-log': isOwnLog(log) }">
                          <div class="time">
                            <p :uk-tooltip="'title:' + moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')">
                              {{ moment(log.createdAt).format('HH:mm') }}
                            </p>
                          </div>
                          <div class="text col-pc-auto col-lgmobile-auto">
                            <p v-if="log.message !== 'note'">
                              {{ $t('call-logs-messages.' + log.message, log.messageReplacements) }}
                            </p>
                            <p
                              v-if="log.message === 'note'"
                              :uk-tooltip="
                                log.messageReplacements.role === 'DISPATCH'
                                  ? `title: ${$t(
                                      'call-logs-messages.' + log.message + '.tooltip-dispatch',
                                      log.messageReplacements
                                    )}`
                                  : `title: ${$t(
                                      'call-logs-messages.' + log.message + '.tooltip-unit',
                                      log.messageReplacements
                                    )}`
                              "
                            >
                              <b>{{ $t(`call-logs-messages.${log.message}.title`, log.messageReplacements) }}</b>
                              : {{ $t(`call-logs-messages.${log.message}.content`, log.messageReplacements) }}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-f col-pc-12 col-mobile-12 input-box">
                    <CustomInput
                      name="code-input"
                      style="height: 40px;"
                      class="custom-input custom-height primary col-pc-12 col-mobile-12"
                      inputClasses="box col-pc-12 col-mobile-12"
                      type="text"
                      :placeholder="$t('call-details-popup_component.add-note')"
                      :onlyBooleanError="true"
                      v-model="$v.note.$model"
                      :model="$v.note"
                      :error="fieldsErrors.note"
                    ></CustomInput>
                    <button class="primary-full send-button" style="height:40px;" @click="addNoteToCall">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div class="column">
                  <div class="units">
                    <p>{{ $t('call-details-popup_component.call-units') }}</p>
                    <UnitSearchInput
                      unitType="POLICE"
                      :excludedResults="callCard && callCard.units ? callCard.units.map(unit => unit.id) : []"
                      @selectedUnit="assignUnit($event)"
                    />
                    <ul class="col-pc-12">
                      <li v-for="unit in callCard.units" :key="unit.number">
                        <div class="main-content col-f">
                          <div class="text col-pc-9">
                            <p class="unit">
                              <b>{{ unit.number }}</b
                              >&nbsp;
                              <span :title="unit.seats.map(seat => seat.badgeNumber).join(', ')"
                                >({{ unit.seats.map(seat => seat.badgeNumber).join(', ') }})</span
                              >
                            </p>
                          </div>
                          <div class="unit-buttons col-pc-3 col-f">
                            <button class="delete orange-full col-pc-6" @click="unassignUnit(unit)">
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="buttons">
                    <button
                      v-if="!isEditingCallCard && (callCard.status === 'OPENED' || callCard.status === 'PENDING')"
                      class="secondary-full"
                      @click="editCallCard()"
                    >
                      {{ $t('actions.edit') }}
                    </button>
                    <button
                      v-if="!isEditingCallCard && callCard.status === 'CLOSED'"
                      class="primary"
                      :disabled="$store.getters['subscriptionPlanEnforcement/hasReachedMaxActiveCalls']"
                      :uk-tooltip="
                        $store.getters['subscriptionPlanEnforcement/hasReachedMaxActiveCalls']
                          ? `title: ${$t('dispatch.has-reached-max-active-calls')}; cls: uk-active`
                          : `title: ; cls: col-display-none`
                      "
                      @click="reopenCallCard()"
                    >
                      {{ $t('actions.reopen') }}
                    </button>
                    <button
                      v-if="!isEditingCallCard && (callCard.status === 'OPENED' || callCard.status === 'PENDING')"
                      class="primary"
                      @click="archiveCallCard()"
                    >
                      {{ $t('actions.archive') }}
                    </button>
                    <button
                      v-if="isEditingCallCard"
                      class="secondary-full"
                      :class="{ spinner: isLoading }"
                      :disabled="isLoading"
                      @click="saveCallCard()"
                    >
                      {{ $t('actions.save') }}
                    </button>
                    <button
                      v-if="isEditingCallCard"
                      class="primary"
                      :disabled="isLoading"
                      @click="cancelEditCallCard()"
                    >
                      {{ $t('actions.cancel') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import Popup from '@/components/Popup.vue'
import UnitSearchInput from '@/components/UnitSearchInput.vue'

export default {
  components: {
    Popup,
    UnitSearchInput
  },
  data() {
    return {
      isLoading: false,
      isEditingCallCard: false,
      fieldsErrors: {},
      callCard: {
        id: '',
        sequentialId: '',
        callerName: '',
        callerPhone: '',
        incidentLocation: '',
        type: 'POLICE',
        description: '',
        logs: []
      },
      initialCallCard: null,
      note: ''
    }
  },
  validations: {
    callCard: {
      callerName: {},
      callerPhone: {},
      category: {},
      incidentLocation: {},
      description: {}
    },
    note: {}
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean,
    callID: String
  },
  apollo: {
    callCard: {
      query: require('@/graphql/dispatcher/fetchCallCard.gql'),
      variables() {
        return {
          id: this.callID
        }
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToCallCard.gql'),
        variables() {
          return {
            id: this.callID
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const callCard = subscriptionData.data.callCard

          if (callCard.mutation === 'UPDATED') {
            return {
              callCard: callCard.node
            }
          }

          if (callCard.mutation === 'DELETED') {
            return {
              callCard: null
            }
          }
        }
      }
    }
  },
  methods: {
    close: function() {
      this.$emit('close')
    },
    editCallCard() {
      this.isEditingCallCard = true
      this.initialCallCard = Object.assign({}, this.callCard)
    },
    archiveCallCard() {
      this.isLoading = true

      const updatedCallCard = {
        status: 'CLOSED',
        logs: {
          create: {
            message: 'archived-call-card',
            messageReplacements: {
              dispatcher: this.$store.getters['dispatcher/dispatchName']
            }
          }
        }
      }

      this.$store
        .dispatch('dispatcher/updateCallCard', { callCardId: this.callID, callCard: updatedCallCard })
        .then(response => {
          this.isLoading = false

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
    },
    reopenCallCard() {
      this.isLoading = true

      const updatedCallCard = {
        status: 'OPENED',
        logs: {
          create: {
            message: 'reopened-call-card',
            messageReplacements: {
              dispatcher: this.$store.getters['dispatcher/dispatchName']
            }
          }
        }
      }

      this.$store
        .dispatch('dispatcher/updateCallCard', { callCardId: this.callID, callCard: updatedCallCard })
        .then(response => {
          this.isLoading = false

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
    },
    addNoteToCall() {
      this.isLoading = true
      this.fieldsErrors = {}

      if (!this.note || (this.note && this.note.trim() === '')) {
        this.fieldsErrors.note = true
        return
      }

      const updatedCallCard = {
        logs: {
          create: {
            message: 'note',
            messageReplacements: {
              sender: `${this.$store.state.selection.character.officer.badgeNumber}`,
              role: 'DISPATCH',
              note: this.note,
              extendedBadgeNumber: this.$store.getters['dispatcher/dispatchName']
            }
          }
        }
      }

      this.$store
        .dispatch('dispatcher/updateCallCard', { callCardId: this.callID, callCard: updatedCallCard })
        .then(response => {
          this.isLoading = false
          this.note = ''

          this.$notify({
            type: 'success',
            title: this.$t('notifications.note-sent.title'),
            text: this.$t('notifications.note-sent.text')
          })
        })
    },
    cancelEditCallCard() {
      this.isEditingCallCard = false
      this.callCard = Object.assign({}, this.initialCallCard)
    },
    saveCallCard() {
      this.isLoading = true

      let updatedCallCard = Object.assign({}, this.callCard)
      delete updatedCallCard.id
      delete updatedCallCard.__typename
      delete updatedCallCard.units
      delete updatedCallCard.callLogs
      updatedCallCard.logs = {
        create: {
          message: 'updated-call-card',
          messageReplacements: {
            dispatcher: `${this.$store.state.selection.character.firstName} ${this.$store.state.selection.character.lastName}`
          }
        }
      }

      this.$store
        .dispatch('dispatcher/updateCallCard', { callCardId: this.callID, callCard: updatedCallCard })
        .then(response => {
          this.isEditingCallCard = false
          this.isLoading = false
          this.initialCallCard = Object.assign({}, this.callCard)

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
    },
    assignUnit(unit) {
      this.$store.dispatch('dispatcher/assignUnit', { callCardId: this.callID, unit: unit }).then(response => {
        this.$notify({
          type: 'success',
          title: this.$t('notifications.unit-assigned.title'),
          text: this.$t('notifications.unit-assigned.text', { unitNumber: unit.number })
        })
      })
    },
    unassignUnit(unit) {
      this.$store.dispatch('dispatcher/unassignUnit', { callCardId: this.callID, unit: unit }).then(response => {
        this.$notify({
          type: 'success',
          title: this.$t('notifications.unit-unassigned.title'),
          text: this.$t('notifications.unit-unassigned.text', { unitNumber: unit.number })
        })
      })
    },
    isOwnLog(log) {
      let logOwned = false

      if (log.messageReplacements) {
        Object.values(log.messageReplacements).forEach(replacement => {
          if (replacement.includes(this.$store.state.selection.character.officer.badgeNumber)) {
            logOwned = true
          }
        })
      }

      return logOwned
    }
  },
  computed: {
    logs() {
      return this.callCard ? this.callCard.callLogs || this.callCard.logs : []
    }
  }
}
</script>
