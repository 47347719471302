<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="unit-dispatch default"
  >
    <div
      class="_content col-pc-10 col-lgmobile-10 col-margin-auto"
      v-if="unit"
    >
      <div class="unit-dispatch">
        <div class="main-form">
          <div class="frame">
            <div class="_top col-f">
              <div class="left col-all-4"></div>
              <div class="center col-all-4">
                <h2 class="unit_number">{{ $t('unit-popup_component.title') }} #{{ unit.number }}</h2>
              </div>
              <div class="right col-all-4">
                <button
                  v-if="unit.onDuty"
                  class="primary"
                  @click="offDutyUnit"
                >
                  {{ $t('unit-popup_component.off-duty') }}
                </button>
                <button
                  v-if="!unit.onDuty"
                  class="primary"
                  @click="onDutyUnit"
                >
                  {{ $t('unit-popup_component.on-duty') }}
                </button>
              </div>
            </div>
            <div class="rows col-pc-12 col-f">
              <div class="row left-row col-pc-4">
                <div class="columns col-pc-11">
                  <div class="column">
                    <h3>{{ $t('dispatch.role') }}</h3>
                    <vSelect
                      class="custom-select primary box col-pc-12"
                      v-model="unit.type"
                      :reduce="type => type.value"
                      :disabled="true"
                      :clearable="false"
                      :selectable="option => option.value.includes('POLICE')"
                      :options="[
                        { label: $t('dispatch.unit-type.police'), value: 'POLICE' },
                        { label: $t('dispatch.unit-type.firefighter'), value: 'FIREFIGHTER' },
                        { label: $t('dispatch.unit-type.paramedic'), value: 'PARAMEDIC' }
                      ]"
                    ></vSelect>
                  </div>
                  <div class="column">
                    <h3>{{ $t('dispatch.rank') }}</h3>
                    <vSelect
                      class="custom-select primary box col-pc-12"
                      :reduce="rank => rank.value"
                      :disabled="true"
                      :clearable="false"
                      value="PATROL_UNIT"
                      :options="[{ label: $t('dispatch.unit-rank.patrol-unit'), value: 'PATROL_UNIT' }]"
                    ></vSelect>
                  </div>
                  <div class="column">
                    <h3>{{ $t('dispatch.status') }}</h3>
                    <vSelect
                      v-if="unit.status"
                      class="custom-select primary box col-pc-12"
                      v-model="unit.status.id"
                      :reduce="status => status.value"
                      :clearable="false"
                      :options="mappedStatuses"
                      @input="updateUnitStatus"
                    ></vSelect>
                    <p v-else>{{ $t('unit-popup_component.never-used') }}</p>
                  </div>
                </div>
              </div>
              <div
                class="row right-row col-pc-8"
                style="z-index: 100"
              >
                <div class="columns">
                  <div class="column form-box col-pc-12">
                    <div class="seats-number col-f">
                      <div class="col-pc-6">
                        <h3 style="margin: 0;">{{ $t('unit-popup_component.seats') }}</h3>
                      </div>
                      <div class="col-pc-6 uk-text-right col-f">
                        <h3>{{ unit.seats.length }} / {{ unit.maxSeats }}</h3>
                      </div>
                    </div>
                    <div class="allSeats">
                      <table>
                        <tr
                          class="seat"
                          v-for="seatedOfficer in unit.seats"
                          :key="seatedOfficer.id"
                        >
                          <p class="col-pc-4">{{ seatedOfficer.badgeNumber }}</p>
                          <p
                            class="col-pc-4"
                            :uk-tooltip="
                              checkPermissions()(permissions, 'admin')
                                ? `title: ${seatedOfficer.civilian.creator.email}; cls: uk-active`
                                : `title: ; cls: col-display-none`
                            "
                            @click="moveOfficer(seatedOfficer)"
                          >
                            {{ `${seatedOfficer.civilian.firstName} ${seatedOfficer.civilian.lastName}` }}
                          </p>
                          <a
                            class="col-pc-4"
                            @click="expulseOfficer(seatedOfficer.id)"
                          >{{
                            $t('unit-popup_component.actions.expulse')
                          }}</a>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="column">
                    <h3 class="active-calls-title">{{ $t('unit-details_component.assigned-call') }}</h3>
                    <div class="active-calls">
                      <CallSearchInput
                        v-if="!unit.activeCall"
                        :excludedResults="unit.activeCall ? [unit.activeCall] : []"
                        @selectedCall="assignCall"
                      ></CallSearchInput>
                      <ul class="col-pc-12">
                        <li v-if="unit.activeCall">
                          <div class="main-content col-f">
                            <div class="text col-pc-9">
                              <div class="call col-f col-pc-12">
                                <p class="id col-pc-3 uk-text-left">{{ unit.activeCall.sequentialId }}</p>
                                <p
                                  class="category col-pc-8 uk-text-center"
                                  :title="unit.activeCall.category"
                                >
                                  {{ unit.activeCall.category }}
                                </p>
                                <div class="blank col-pc-1"></div>
                              </div>
                            </div>
                            <div class="call-buttons col-pc-3 col-f">
                              <button
                                class="delete orange-full col-pc-6"
                                @click="unassignCall"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import { mapGetters } from 'vuex'
import Popup from './Popup'
import CallSearchInput from '@/components/CallSearchInput'

export default {
  name: 'UnitDetails',
  components: {
    Popup,
    CallSearchInput
  },
  data() {
    return {
      unit: null,
      activeCalls: [],
      transferUnit: {}
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean,
    unitId: String
  },
  apollo: {
    statuses: {
      query: require('@/graphql/police/fetchStatuses.gql'),
      variables() {
        return {
          communityId: this.$store.state.selection.community.community.id
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.community.statuses
    },
    unit: {
      query: require('@/graphql/dispatcher/fetchUnit.gql'),
      fetchPolicy: 'network-only',
      variables() {
        return {
          unitId: this.unitId
        }
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToUnit.gql'),
        variables() {
          return {
            unitId: this.unitId
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const unit = subscriptionData.data.unit
          console.log(unit.node.seats)

          // Note: Small hacks to trigger reactivity
          this.unit.seats = unit.node.seats
          this.unit.activeCall = unit.node.activeCall
          this.unit.status = unit.node.status

          if (!unit.node.onDuty) {
            this.$emit('unitOffDuty')
          }

          return {
            unit: unit.node
          }
        }
      }
    }
  },
  computed: {
    mappedStatuses() {
      return this.statuses
        .filter(status => status.category !== 'PANIC')
        .map(status => {
          return {
            label: status.display,
            value: status.id
          }
        })
    },
    permissions() {
      return this.$store.state.selection.community ? this.$store.state.selection.community.permissionsValue : 0
    }
  },
  methods: {
    ...mapGetters('auth', ['checkPermissions']),
    updateUnitStatus() {
      // TODO: i18n -> DISPATCH
      this.$store
        .dispatch('police/updateStatus', {
          status: this.statuses.filter(status => status.id === this.unit.status.id)[0],
          unit: this.unit,
          changedBy: 'DISPATCH'
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    expulseOfficer(toExpulse) {
      this.$store
        .dispatch('dispatcher/expulseFromUnit', { officerId: toExpulse, unitId: this.unit.id })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    offDutyUnit() {
      this.$store
        .dispatch('dispatcher/updateDutyUnit', { unitId: this.unit.id, dutyStatus: false })
        .then(() => {
          this.$emit('unitOffDuty')
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    onDutyUnit() {
      this.$store
        .dispatch('dispatcher/updateDutyUnit', { unitId: this.unit.id, dutyStatus: true })
        .then(() => {
          this.$emit('unitOffDuty')
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    assignCall(call) {
      this.$store.dispatch('dispatcher/assignUnit', { callCardId: call.id, unit: this.unit }).catch(error => {
        this.$store.dispatch('errorManager/showError', { error: error })
      })
    },
    unassignCall() {
      this.$store
        .dispatch('dispatcher/unassignUnit', { callCardId: this.unit.activeCall.id, unit: this.unit })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    moveOfficer(officer) {
      // Get list of units
      this.$store
        .dispatch('dispatcher/fetchAllUnits')
        .then(response => {
          const units = {}
          response.forEach(unit => {
            if (unit.id !== this.unit.id) {
              units[unit.id] = unit.number
            }
          })

          this.$swal({
            title: this.$t('unit-details_component.transfer-officer.header', { officer: `(${officer.badgeNumber}) ${officer.civilian.firstName} ${officer.civilian.lastName}` }),
            input: 'select',
            inputOptions: units,
            inputPlaceholder: this.$t('unit-details_component.transfer-officer.select-title'),
            showCancelButton: true,
            inputValidator: function(value) {
              return new Promise(function(resolve, reject) {
                resolve()
              })
            }
          }).then(result => {
            this.$store
              .dispatch('dispatcher/transferToVehicle', {
                oldUnitId: this.unit.id,
                newUnitId: result.value,
                officerId: officer.id
              })
              .then(() => {
                // If we encounter problem with the timing, we can uncomment this, and add 5 seconds which should be enouph to let the other client make it's update
                // this.unit.seats = this.unit.seats.filter(seat => seat.id !== officer.id)
                this.$swal({
                  type: 'success',
                  html: this.$t('unit-details_component.transfer-officer.success-text', {
                    officer: `(${officer.badgeNumber}) ${officer.civilian.firstName} ${officer.civilian.lastName}`,
                    unit: units[result.value]
                  })
                })
              })
              .catch(error => {
                this.$store.dispatch('errorManager/showError', { error: error })
              })
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    }
  }
}
</script>
