<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    class="archives-dispatch default"
  >
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto">
      <div class="archives-dispatch">
        <div class="main-form">
          <div class="frame">
            <div class="row">
              <div class="table col-pc-12 archives-dispatch">
                <div class="tr header noselect">
                  <div
                    class="th id col-pc-2"
                    :class="{ active: callsSort === 'sequentialId' }"
                    @click="sort('sequentialId')"
                  >
                    {{ $t('archives-dispatch_component.id') }}
                    <i
                      class="fas fa-long-arrow-alt-down"
                      v-if="callsSortDir === 'asc' && callsSort === 'sequentialId'"
                    ></i>
                    <i
                      class="fas fa-long-arrow-alt-up"
                      v-if="callsSortDir === 'desc' && callsSort === 'sequentialId'"
                    ></i>
                  </div>
                  <div
                    class="th category col-pc-6"
                    :class="{ active: callsSort === 'category' }"
                    @click="sort('category')"
                  >
                    {{ $t('archives-dispatch_component.category') }}
                    <i class="fas fa-long-arrow-alt-down" v-if="callsSortDir === 'asc' && callsSort === 'category'"></i>
                    <i class="fas fa-long-arrow-alt-up" v-if="callsSortDir === 'desc' && callsSort === 'category'"></i>
                  </div>
                  <div
                    class="th priority col-pc-2"
                    :class="{ active: callsSort === 'priority' }"
                    @click="sort('priority')"
                  >
                    {{ $t('archives-dispatch_component.priority') }}
                    <i class="fas fa-long-arrow-alt-down" v-if="callsSortDir === 'asc' && callsSort === 'priority'"></i>
                    <i class="fas fa-long-arrow-alt-up" v-if="callsSortDir === 'desc' && callsSort === 'priority'"></i>
                  </div>
                  <div class="th time col-pc-2" :class="{ active: callsSort === 'time' }" @click="sort('time')">
                    {{ $t('archives-dispatch_component.time') }}
                    <i class="fas fa-long-arrow-alt-down" v-if="callsSortDir === 'asc' && callsSort === 'time'"></i>
                    <i class="fas fa-long-arrow-alt-up" v-if="callsSortDir === 'desc' && callsSort === 'time'"></i>
                  </div>
                </div>
                <div class="tr" v-for="call in sortedCalls" :key="call.id" @click="openCallDetails(call.id)">
                  <div class="td id col-pc-2" :title="call.sequentialId">
                    <span>{{ call.sequentialId }}</span>
                  </div>
                  <div class="td category col-pc-6" :title="call.category">
                    <span>{{ call.category }}</span>
                  </div>
                  <div class="td priority col-pc-2" :title="call.priority">
                    <span>{{ call.priority }}</span>
                  </div>
                  <div class="td time col-pc-2" :title="moment(call.createdAt).format('YYYY-MM-DD HH:mm:ss')">
                    {{ moment(call.createdAt).format('HH:mm') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import Popup from '@/components/Popup'

export default {
  components: {
    Popup
  },
  data() {
    return {
      openCallId: '',
      callCards: [],
      callsSort: 'id',
      callsSortDir: 'asc'
    }
  },
  props: {
    page: String,
    name: String,
    title: String,
    width: Number,
    height: Number,
    parent: Boolean
  },
  apollo: {
    callCards: {
      query: require('@/graphql/dispatcher/fetchCalls.gql'),
      fetchPolicy: 'network-only',
      variables: {
        status: ['CLOSED']
      },
      subscribeToMore: {
        document: require('@/graphql/dispatcher/subscribeToCalls.gql'),
        updateQuery: (previousResult, { subscriptionData }) => {
          const callCard = subscriptionData.data.callCard

          if (callCard.mutation === 'UPDATED') {
            if (callCard.node.status === 'OPENED' || callCard.node.status === 'PENDING') {
              return {
                callCards: previousResult.callCards.filter(card => card.id !== callCard.node.id)
              }
            }
            if (
              callCard.node.status === 'CLOSED' &&
              (callCard.previousValues.status === 'OPENED' || callCard.previousValues.status === 'PENDING')
            ) {
              return {
                callCards: [...previousResult.callCards, callCard.node]
              }
            }
            return {
              callCards: previousResult.callCards.map(card => (card.id === callCard.node.id ? callCard.node : card))
            }
          }

          if (callCard.mutation === 'DELETED') {
            return {
              callCards: previousResult.callCards.filter(card => card.id !== callCard.previousValues.id)
            }
          }
        }
      }
    }
  },
  methods: {
    sort: function(item) {
      // if item == current sort, reverse
      if (item === this.callsSort) {
        this.callsSortDir = this.callsSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.callsSort = item
    },
    openCallDetails(callId) {
      this.openCallId = callId
      this.$emit('openCallDetails', this.openCallId)
    }
  },
  computed: {
    sortedCalls: function() {
      return this.callCards.slice().sort((a, b) => {
        let modifier = 1
        if (this.callsSortDir === 'desc') modifier = -1

        if (a[this.callsSort] < b[this.callsSort]) return -1 * modifier
        if (a[this.callsSort] > b[this.callsSort]) return 1 * modifier

        return 0
      })
    }
  }
}
</script>
